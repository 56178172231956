/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-03-21 09:45:30
 */
export default {
    label: '页面跳转',
    type: 'jump',
    property: '',
    value: {}
}
