/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:55
 */
export default {
  label: '圆角',
  type: 'radius',
  property: '',
  value: {
    left:0,
    right:0,
    top:0,
    bottom:0
  },
  options: {
    min: 0,
    max: 30
  }
}
