/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-25 19:59:48
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-25 20:17:06
 */
export default [
  'icon-sousuo',
  'icon-shangpin-meau',
  'icon-cart',
  'icon-message',
  'icon-sort',
  'icon-my',
  'icon-daojishi',
  'icon-biaotizujian',
  'icon-gonggao',
  'icon-line',
  'icon-image',
  'icon-swiper',
  'icon-daohang',
  'icon-lajitong',
  'icon-zujian',
  'icon-shoupiao',
  'icon-vip',
  'icon-map',
  'icon-attachent',
  'icon-hot1'
]
