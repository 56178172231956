/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:15:40
 */
export default {
    label: '地图选项',
    type: 'map',
    property: '',
    value: {}
}
